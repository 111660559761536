import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Grid,
  List,
  ListItem,
  DialogContentText,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import afip from "../api/afip";
import categoriasIVA from "../api/categoriasIVA";
import Clientes from "../api/clientes";
import condicionesVenta from "../api/condicionesVenta";
import Percepciones from "../api/percepciones";
import localidades from "../api/localidad";
import Cliente from "../api/crearCliente";

const AltaCliente = () => {
  const [cuit, setCuit] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [idLocalidad, setIdLocalidad] = useState("");
  const [calle, setCalle] = useState("");
  const [catIva, setCatIva] = useState("");
  const [condVenta, setCondVenta] = useState("");
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [condVentasList, setCondVentasList] = useState([]);
  const [categoriaIvaList, setCategoriaIvaList] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editableCondVenta, setEditableCondVenta] = useState(false);
  const [editableCodigoPostal, setEditableCodigoPostal] = useState(false);
  const [editableCalle, setEditableCalle] = useState(false);
  const [editableCatIva, setEditableCatIva] = useState(false);
  const [editableRazonSocial, setEditableRazonSocial] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [localidadesList, setLocalidadesList] = useState([]);
  const [isLoadingLocalidades, setIsLoadingLocalidades] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [openRecoverDialog, setOpenRecoverDialog] = useState(false);
  const [existingCliente, setExistingCliente] = useState(null);

  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const habilitarAltaClientes = puesto.habilitarAltaClientes;

  const isCuitValid = cuit => {
    // Remover guiones y espacios
    const cuitClean = cuit.replace(/-/g, "").trim();

    // Verificar que tenga 11 caracteres y que sean todos números
    if (cuitClean.length !== 11 || !/^\d+$/.test(cuitClean)) {
      return false;
    }

    // Cálculo del dígito verificador
    const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    const digits = cuitClean.split("").map(Number);
    const checkDigit = digits.pop(); // El último dígito es el dígito verificador

    const calculatedSum = weights.reduce((sum, weight, index) => sum + weight * digits[index], 0);
    const remainder = calculatedSum % 11;
    const calculatedCheckDigit = remainder === 0 ? 0 : 11 - remainder;

    return checkDigit === calculatedCheckDigit;
  };

  const theme = useTheme();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSearchString("");
    setLocalidadesList([]);
  };

  const handleSearchLocalidad = async () => {
    setIsLoadingLocalidades(true);
    try {
      const response = await localidades.getLocalidad(1, searchString);
      if (response?.data?.localidades?.length > 0) {
        setLocalidadesList(response.data.localidades);
      } else {
        setLocalidadesList([]);
        setErrorModalMessage(`No se encontraron localidades para el criterio de búsqueda: ${searchString}`);
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error al buscar localidades:", error);
      setErrorModalMessage("Error al obtener la localidad de AFIP. Por favor, intente más tarde.");
      setErrorModalOpen(true);
    } finally {
      setIsLoadingLocalidades(false);
    }
  };

  const handleSelectLocalidad = selectedLocalidad => {
    setCodigoPostal(selectedLocalidad.codigoPostal);
    setLocalidad(selectedLocalidad.nombreLocalidad.trim());
    setIdLocalidad(selectedLocalidad.idLocalidad);
    handleCloseModal();
    console.log("Localidad seleccionada:", selectedLocalidad.idLocalidad);
  };

  const validateForm = () => {
    return cuit.trim() !== "" && razonSocial !== "" && codigoPostal !== "" && calle !== "" && catIva !== "" && condVenta !== "";
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsLoading(false);
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya === "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    navigate("/clientessearchpage");
  };

  useEffect(() => {
    const fetchCategoriasIVA = async () => {
      try {
        const listadoCatIVA = await categoriasIVA.getCategoriasIVA();
        const formatoCatIva = listadoCatIVA.map(condicion => ({
          value: condicion.idCategoriaIVA.toString(),
          idCategoriaIVA: condicion.idCategoriaIVA.toString(),
          label: condicion.descripcionCategoriaIVA,
          abreviaturaCategoriaIVA: condicion.abreviaturaCategoriaIVA,
          codigoAFIPCategoriaIVA: condicion.codigoAFIPCategoriaIVA,
        }));
        setCategoriaIvaList(formatoCatIva);
        console.log(formatoCatIva);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchCondVenta = async () => {
      try {
        const listadoCondVenta = await condicionesVenta.getCondVenta();
        const formatoCondVentas = listadoCondVenta.map(condicion => ({
          value: condicion.idCondicionVenta.toString(),
          idCondicionVenta: condicion.idCondicionVenta.toString(),
          label: condicion.descripcionCondicionVenta,
        }));
        setCondVentasList(formatoCondVentas);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCategoriasIVA();
    fetchCondVenta();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setModalWidth(window.innerWidth < 700 ? "360px" : "900px");
    };

    handleResize();
  }, []);

  const extractNumberFromCalle = calle => {
    const matches = calle.match(/\d+/g);
    return matches ? matches[matches.length - 1] : "N/A";
  };

  const handleAlta = async () => {
    console.log("Dando de alta cliente...");

    const selectedCatIva = categoriaIvaList.find(option => option.value === catIva.toString());
    const selectedCondVenta = condVentasList.find(option => option.value === condVenta);
    console.log(selectedCatIva);
    const clienteObj = {
      razonSocial: razonSocial,
      IdTipoDocumento: 4, // CUIT
      calle: `${calle} Piso:0`,
      localidad: {
        idLocalidad: idLocalidad,
        nombreLocalidad: localidad,
        provincia: {
          nombreProvincia: provincia,
        },
      },
      numeroDocumento: formatCuit(cuit),
      categoriaIVA: {
        descripcionCategoriaIVA: selectedCatIva?.label || "",
        abreviaturaCategoriaIVA: selectedCatIva?.abreviaturaCategoriaIVA || "",
        codigoAFIPCategoriaIVA: parseInt(catIva),
      },
      condicionVenta: {
        descripcionCondicionVenta: selectedCondVenta?.label || "",
        condicionVentaContado: true,
      },
    };

    setIsLoading(true);

    try {
      const response = await Cliente.crear({
        razonSocial: clienteObj.razonSocial,
        numeroDocumento: clienteObj.numeroDocumento,
        idCondicionVenta: parseInt(condVenta),
        idCategoriaIVA: parseInt(catIva),
        idSectorComercializacion: 0,
        idTipoDocumento: clienteObj.IdTipoDocumento,
        idLocalidad: clienteObj.localidad.idLocalidad,
        listaPrecio: 0,
        calle: clienteObj.calle,
        puerta: extractNumberFromCalle(calle),
        codigoPostal: codigoPostal,
        idEmpleadoVendedor: null,
      });

      if (response?.idCliente) {
        clienteObj.idCliente = response.idCliente;
        localStorage.setItem("cliente", JSON.stringify(clienteObj));

        const esPlaya = localStorage.getItem("solicitarAforadores");
        navigate(esPlaya === "true" ? "/dashboard" : "/dashboardmini");
      } else {
        alert("Error al crear el cliente: " + response?.message || "Respuesta inesperada.");
      }
    } catch (error) {
      console.error("Error al crear cliente:", error);
      alert("Ocurrió un error al crear el cliente. Intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecover = () => {
    if (existingCliente) {
      localStorage.setItem("cliente", JSON.stringify(existingCliente.data.clientes[0]));
      const esPlaya = localStorage.getItem("solicitarAforadores");
      navigate(esPlaya === "true" ? "/dashboard" : "/dashboardmini");
    }
    setOpenRecoverDialog(false);
  };

  const handleCancelRecover = () => {
    setOpenRecoverDialog(false);
  };

  const handleSearch = async cuit => {
    // Validar CUIT antes de proceder
    if (!isCuitValid(cuit)) {
      setErrorModalMessage("El CUIT ingresado no es válido");
      setErrorModalOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      let cliente = null;

      try {
        // Intentar obtener el cliente de la base de datos
        cliente = await Clientes.getClientes(formatCuit(cuit));
        console.log("Respuesta de Clientes.getClientes:", cliente);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Cliente no encontrado en la base de datos. Continuando con AFIP...");
        } else {
          // Si ocurre otro error distinto de 404, mostrar mensaje de error
          console.error("Error al verificar cliente en la base de datos:", error);
          setErrorModalMessage("Error al verificar el cliente en la base de datos. Intente nuevamente.");
          setErrorModalOpen(true);
          setIsLoading(false);
          return;
        }
      }

      // Si el cliente existe, no permitir continuar
      if (cliente && cliente !== "") {
        setExistingCliente(cliente); // Guarda los datos del cliente existente
        setOpenRecoverDialog(true);
        setIsLoading(false);
        return;
      }

      // Consultar los datos en AFIP si el cliente no existe en la base de datos
      const userAfip = await afip.getClienteAfip(cuit);
      if (!userAfip) {
        setAlertOpen(true);
        setIsLoading(false);
        return;
      }

      // Rellenar los datos del cliente en el formulario
      let newRazonSocial = userAfip.razonSocial ? userAfip.razonSocial.slice(0, 40) : `${userAfip.apellido}, ${userAfip.nombre}`;
      setRazonSocial(newRazonSocial);
      setCodigoPostal(userAfip.codPostal || "");
      setLocalidad("");
      setProvincia(userAfip.descripcionProvincia || "");
      setCalle(userAfip.direccion ? userAfip.direccion.slice(0, 40) : "");
      setCondVenta(1); // Set default "CONTADO"
      setCatIva(userAfip.idCategoriaIVASegunCaldenOil);

      // Habilitar edición solo si los datos no están disponibles
      setEditableCodigoPostal(!userAfip.codPostal);
      setEditableCalle(!userAfip.direccion);
      setEditableCatIva(!userAfip.idCategoriaIVASegunCaldenOil);
      setEditableRazonSocial(!userAfip.razonSocial);

      // Buscar la localidad asociada al código postal
      try {
        const response = await localidades.getLocalidad(1, userAfip.codPostal);
        if (response?.data?.localidades?.length > 0) {
          const localidadData = response.data.localidades[0];
          setIdLocalidad(localidadData.idLocalidad);
          setLocalidad(localidadData.nombreLocalidad.trim());
          console.log("idLocalidad encontrado:", localidadData.idLocalidad);
        } else {
          setLocalidad("");
          setIdLocalidad("");
          setErrorModalMessage(`No se encontró ninguna localidad para el código postal ${userAfip.codPostal}.`);
          // setErrorModalOpen(true);
          // ENDPOINT DE LOCALIDAD ESTO ES UN PARCHE!!
        }
      } catch (localidadError) {
        if (localidadError.response && localidadError.response.status === 404) {
          setErrorModalMessage(`No se encontró ninguna localidad para el código postal ${userAfip.codPostal}.`);
        } else {
          console.error("Error al consultar el endpoint de localidades:", localidadError);
          setErrorModalMessage("Error al obtener la localidad de AFIP. Por favor, intente más tarde.");
        }
        // setErrorModalOpen(true);
        // ENDPOINT DE LOCALIDAD ESTO ES UN PARCHE!!
      }
    } catch (error) {
      console.error("Error al buscar cliente:", error);

      // Si el error es 500, buscar localidad por nombre
      if (error.response && error.response.status === 500) {
        setEditableCodigoPostal(true);
        setEditableCalle(true);
        setEditableCatIva(true);
        setEditableRazonSocial(true);
        setEditableCondVenta(true);

        setRazonSocial("");
        setCodigoPostal("");
        setCalle("");
        setCatIva("");
        setCondVenta(1); // Default to CONTADO
        setLocalidad("");

        if (localidad) {
          try {
            const localidadResponse = await localidades.getLocalidad(1, localidad);
            if (localidadResponse?.data?.localidades?.length > 0) {
              const localidadData = localidadResponse.data.localidades[0];
              setCodigoPostal(localidadData.codigoPostal);
              setIdLocalidad(localidadData.idLocalidad);
              console.log("Localidad encontrada:", localidadData);
            } else {
              console.log("No se encontró una localidad para el nombre:", localidad);
            }
          } catch (localidadError) {
            console.error("Error al buscar localidad por nombre:", localidadError);
          }
        }
      } else {
        setAlertOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatCuit = value => {
    const newValue = value.replace(/\D/g, "");
    return newValue.replace(/^(\d{2})(\d{8})(\d{1})$/, "$1-$2-$3");
  };

  const handleAgregar = async () => {
    console.log("Agregando cliente...");
    const clienteObj = {};
    clienteObj.razonSocial = razonSocial;
    clienteObj.IdTipoDocumento = 4;
    clienteObj.calle = calle;
    clienteObj.localidad = {
      idLocalidad: idLocalidad,
      nombreLocalidad: localidad,
      provincia: {
        nombreProvincia: provincia,
      },
    };

    clienteObj.numeroDocumento = formatCuit(cuit);

    const selectedCatIva = categoriaIvaList.find(option => option.value === catIva.toString());
    console.log("Categoría IVA seleccionada:", selectedCatIva);

    if (!selectedCatIva) {
      console.error("No se encontró la categoría IVA seleccionada.");
      return;
    }

    clienteObj.categoriaIVA = {
      descripcionCategoriaIVA: selectedCatIva.label,
      abreviaturaCategoriaIVA: selectedCatIva.abreviaturaCategoriaIVA,
      codigoAFIPCategoriaIVA: selectedCatIva.idCategoriaIVA || "Sin valor",
    };

    const selectedCondVenta = condVentasList.find(option => option.value === condVenta);
    clienteObj.condicionVenta = {
      descripcionCondicionVenta: selectedCondVenta ? selectedCondVenta.label : "",
      condicionVentaContado: true,
    };

    localStorage.setItem("cliente", JSON.stringify(clienteObj));

    // Verificar si la categoría IVA es "CONSUMIDOR FINAL"
    if (selectedCatIva.abreviaturaCategoriaIVA === "CF") {
      if (validateForm()) {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        navigate(esPlaya === "true" ? "/dashboard" : "/dashboardmini");
      }
    }

    const numeroDocumento = clienteObj.numeroDocumento.replace(/-/g, "");
    setIsLoading(true);

    try {
      const percepciones = await Percepciones.getPercepciones(numeroDocumento);
      localStorage.setItem("percepciones", percepciones);
      if (percepciones) {
        handleOpenConfirmDialog();
      } else if (validateForm()) {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        navigate(esPlaya === "true" ? "/dashboard" : "/dashboardmini");
      }
    } catch (error) {
      console.error("Error al obtener percepciones:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "49%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "90%",
        }}
      >
        <TextField
          label="CUIT"
          value={formatCuit(cuit)}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          onChange={e => setCuit(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleSearch(cuit)}>
                <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "0.85rem", color: "gray" },
          }}
        />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 360 : 290,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Razón Social"
                  value={razonSocial || ""}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  onChange={e => setRazonSocial(e.target.value)}
                  disabled={!editableRazonSocial}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Código Postal"
                      value={`${codigoPostal}${localidad ? ` (${localidad})` : ""}`}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 2.5 }}
                      onClick={handleOpenModal}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { fontSize: "0.85rem", color: "gray" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Calle"
                      value={calle || ""}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 0.5 }}
                      onChange={e => setCalle(e.target.value)}
                      disabled={!editableCalle}
                      InputLabelProps={{
                        style: { fontSize: "0.85rem", color: "gray" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Categoría IVA"
                  value={catIva}
                  select
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                  disabled={!editableCatIva}
                  onChange={e => setCatIva(e.target.value)}
                >
                  {categoriaIvaList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Condición Venta"
                  value={condVenta}
                  select
                  disabled
                  defaultValue="CONTADO"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                  onChange={e => setCondVenta(e.target.value)}
                >
                  {condVentasList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: habilitarAltaClientes ? "space-between" : "flex-end",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {habilitarAltaClientes && (
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: 0,
                    fontWeight: "bold",
                    fontSize: 17,
                    marginTop: "19px",
                    backgroundColor: theme.bandera.colorSecundario,
                    ":hover": {
                      backgroundColor: theme.bandera.colorSecundario,
                    },
                  }}
                  disabled={!validateForm()}
                  onClick={handleAlta}
                >
                  ALTA
                </Button>
              )}

              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  marginTop: "19px",
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                disabled={!validateForm()}
                onClick={handleAgregar}
              >
                ACEPTAR
              </Button>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Cliente con percepciones"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem" }}>El cliente percibe percepciones ¿desea continuar?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Cancelar
            </Typography>
          </Button>

          <Button onClick={handleConfirm} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"AFIP"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1.25rem" }}>La clave ingresada no es una CUIT ni una CDI</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de búsqueda */}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Buscar Localidad</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre de Localidad"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2, mt: 2 }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearchLocalidad} disabled={isLoadingLocalidades}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          {isLoadingLocalidades ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {localidadesList.map(localidad => (
                <ListItem key={localidad.idLocalidad} button onClick={() => handleSelectLocalidad(localidad)}>
                  <ListItemText primary={localidad.nombreLocalidad.trim()} secondary={`Código Postal: ${localidad.codigoPostal}`} />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{ color: theme.bandera.colorSecundario }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorModalOpen} onClose={() => setErrorModalOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Facturador</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorModalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorModalOpen(false)} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRecoverDialog} onClose={handleCancelRecover} aria-labelledby="recover-dialog-title" aria-describedby="recover-dialog-description">
        <DialogTitle id="recover-dialog-title">{"Facturador"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="recover-dialog-description">El CUIT {formatCuit(cuit)} está registrado. ¿Recuperar sus datos?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRecover} color="primary">
            No
          </Button>
          <Button onClick={handleRecover} color="primary" autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AltaCliente;
